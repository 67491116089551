/**
 * Created by prasong.k on 3/8/2559.
 */

Vue.filter('format_date', function (value,format) {
  if(!value) return '';

  format = format == undefined ? 'D MMM YYYY' : format;

  function isInteger(x) { return typeof x === "number" && isFinite(x) && Math.floor(x) === x; }

  if(isInteger(value)) {
    return moment.unix(value).format(format);
  }else {
    return moment(value).format(format);
  }

});

Vue.filter('int', function (value) {
  return value ? parseInt(value) : 0;
});

Vue.filter('isEmpty', function (obj) {
  var key;
  for(key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
});

Vue.filter('number_format', function (number, decimals, decPoint, thousandsSep) {
  decimals = decimals ? decimals : 0;
  decPoint = decPoint ? decPoint : '.';
  thousandsSep = thousandsSep ? thousandsSep : ',';

  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  var n = !isFinite(+number) ? 0 : +number;
  var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
  var dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
  var s = '';

  var toFixedFix = function (n, prec) {
    var k = Math.pow(10, prec);
    return '' + (Math.round(n * k) / k)
        .toFixed(prec)
  };

  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }

  return s.join(dec)
});
